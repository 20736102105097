export default {
  "makalin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makalin"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ようこそ"])},
  "logInToPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインして商品をご購入ください...."])},
  "LogInWithName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン（ユーザー名）"])},
  "LogInWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン（電話番号）"])},
  "notFoundProduct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["販売済み ", _interpolate(_list(0)), " 個"])},
  "addfriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達追加"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗へ"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
  "soldAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
  "goToStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール画像を変更"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男性"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女性"])},
  "changeProfileImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー名"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP を受け取る"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この電話番号はシステムに登録されていません"])},
  "getOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "notFoundPhoneInSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または"])},
  "pleaseContactAddmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを設定"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードの確認"])},
  "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインしてください"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報の更新に成功しました"])},
  "reNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの変更に成功しました"])},
  "loginFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支店"])},
  "successUpdateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "successUpdatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームに戻る"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バーツ"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
  "backToMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP を受け取れませんか？"])},
  "baht": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("second")), " 秒"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここをクリックして OTP を再送信します"])},
  "didntGetOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号を確認して 100 コインを受け取る"])},
  "otpCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コインを貯めて商品を割引"])},
  "resendOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここで検索..."])},
  "goToVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しばらくお待ちください"])},
  "collectCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続きを読む"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隠す"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリップボードにコピーしました"])},
  "moreDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここをクリックして QR コードをダウンロードします"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済画面に移動しない場合や、まだ処理が完了していない場合は、ここをクリックしてください"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーが発生しました"])},
  "downloadQrCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私たちについて"])},
  "clickToPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いページにリダイレクトされない場合は、ここをクリックするか、SCBアプリケーションがインストールされているか再度確認してください。"])},
  "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の薬"])},
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["について"])}
  },
  "session": {
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薬の形"])}
  },
  "myContinueDrugs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包装の仕様"])},
    "howToUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薬の情報を見る"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ薬のリストがありません"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["午前"])},
    "seeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["午後"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夜"])},
    "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寝る前"])},
    "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
    "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中の薬"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使い終わった薬"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薬のリストを「使い終わった薬」に変更する"])},
    "usingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薬のリストを「使用中の薬」に変更する"])},
    "unusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "setUnusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
    "setUsingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示名"])}
  },
  "profile": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誕生日"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体重 (kg)"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身長 (cm)"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィールを編集する"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誕生日を入力してください"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身長を入力してください"])},
    "pleaseFillEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体重を入力してください"])},
    "pleaseFillBoD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
    "pleaseFillHegiht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薬物アレルギー"])},
    "pleaseFillWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データがありません"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アレルギーのある薬を指定してください"])},
    "allergicDrug": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["慢性疾患"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データがありません"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["慢性疾患を指定してください"])}
    },
    "congenitalDisease": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長期服用薬"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データがありません"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長期服用薬を指定してください"])}
    },
    "personalMedicine": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以内に支払ってください"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認して同意する"])}
    }
  },
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカード/デビットカードで支払う"])},
  "term": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続行"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月/年"])}
  },
  "creditCard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード名義人"])},
    "placeholder": {
      "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXXX XXXX XXXX XXXX"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード番号"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード名義人"])},
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカードの有効期限"])}
    },
    "label": {
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード番号は 16 桁のみ入力してください"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカードの有効期限は月/年で入力してください"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月が正しくありません"])}
    },
    "errs": {
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年が正しくありません"])},
      "expFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV は 3 桁の数字で入力してください"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーが発生しました"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード名義人を入力してください"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカードの有効期限が切れています"])},
      "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカード情報が正しくありません"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文をキャンセルする"])},
      "cardExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文のキャンセル理由を選択してください"])},
      "cardInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン"])}
    }
  },
  "cancellation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンがありません"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンを使用する"])}
  },
  "myCoupon": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンを選択する"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引"])},
    "useCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低金額なし"])},
    "selectCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低金額"])},
    "card": {
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
      "noMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期限なし"])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入り"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品をお気に入りに追加しました"])},
      "noExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品をお気に入りから削除しました"])}
    }
  },
  "myFavorite": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号を確認する"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号を入力してください"])},
    "successRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人確認を行う"])}
  },
  "verifyUser": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料で受け取る"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " コイン"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP を入力してください"])},
    "getFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号でログインする"])},
    "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号を入力してください（Makalin Clinic に接続されています）"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人確認を行う"])}
  },
  "loginWithPhone": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP を入力してください"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを忘れた"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号を入力してください（Makalin Clinic に接続されています）"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人確認を行う"])}
  },
  "forgetPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP を入力してください"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー名でログインする"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の薬"])}
  },
  "loginWithUsername": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カート"])}
  },
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール"])},
    "myDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の買い物"])},
    "myCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達を招待してポイントを貯める"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長期服用薬"])}
  },
  "profileMenu": {
    "myHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所を管理する"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行口座情報"])},
    "myDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプセンター"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私について"])},
    "bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入り"])},
    "helpService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の常用薬"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["よく使う薬の数"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["継続使用している上位 10 の薬"])}
  },
  "myDiaryDrugs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ薬のリストがありません"])},
    "drugGraphTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
    "continueDrugsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おすすめ商品"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["よく購入される商品"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近購入された商品"])},
    "recommendTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての商品をカートに追加する"])},
    "frequencyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて選択する"])},
    "latestPurchaseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて削除する"])},
    "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した商品をカートに追加する"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイカート"])},
    "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに商品がありません"])},
    "addSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ購入する"])}
  },
  "myCart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まとめて購入するとお得です"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品をカートに追加しました"])},
    "shopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品の合計"])},
    "suggestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送料は含まれていません"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品を注文する"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文の概要"])},
    "excludeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送方法"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便で配送する"])}
  },
  "checkout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗で受け取る"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品リスト"])},
    "sendByPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "takeaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品価格（7% の VAT を含む）"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送料"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン割引"])},
    "sumTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["VAT ", _interpolate(_named("percent")), "%"])},
    "shippingFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "sumDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])},
    "sumVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください"])},
    "sumGrandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コード"])},
    "emailDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コードを入力する"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「返金と商品に関するポリシーに同意しました"])},
    "referalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（クリックして詳細を読む）"])},
    "placeHolderReferalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先電話番号: 064-181-6580-2」"])},
    "acceptParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払う"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送先住所を変更する"])},
    "changeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所を追加する"])},
    "goToAddAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送先住所を追加してください"])},
    "pleaseSelectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法を選択してください"])},
    "pleaseSelectPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送先住所を変更する"])}
  },
  "myAddresses": {
    "changeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所録"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ住所を保存していません"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要住所"])},
    "mainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい住所の作成に成功しました"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所の編集に成功しました"])},
    "successUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所の削除に成功しました"])},
    "successDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所を追加する"])}
  },
  "addAddresses": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所を編集する"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内"])},
    "inCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国外"])},
    "abroad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受取人名"])},
    "form": {
      "receiveName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所の種類"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所の詳細（番地、小路、通りなど）"])},
      "addressNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区/郡"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["町/村"])},
      "subDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
      "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要住所として使用する"])},
      "setMainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存する"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキップ"])},
      "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家庭"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仕事"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての商品"])}
    }
  },
  "allProducts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達を招待してポイントを貯める"])}
  },
  "referral": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コード"])},
    "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピーする"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認する"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私たちのポイント"])},
    "myscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達の数"])},
    "friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コードを入力する"])},
    "inputRefCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイントの獲得履歴"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達を招待してポイントを貯める"])},
    "useCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達があなたの招待コードを入力してポイントを獲得しました"])},
    "useQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイントを貯めるためにスキャン"])},
    "useCodeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイント"])},
    "useQRCodeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRコードをスキャンしてポイントを獲得しました"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーが発生しました"])}
  },
  "order": {
    "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いが完了しました"])},
    "successPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いが失敗しました。もう一度支払ってください"])},
    "errorPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いが失敗しました。処理をやり直してください"])},
    "creditCardErrorPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もう一度購入する"])},
    "orderAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文リスト"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文 ID"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品の合計"])},
    "sumTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送料"])},
    "shippingFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン割引"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文合計"])},
    "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得できるポイント"])},
    "gainPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便で配送する"])},
    "sendByPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追跡番号"])},
    "parcelTrackNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品リスト"])},
    "itemTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文をキャンセルする"])},
    "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームに戻る"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ追跡番号がありません"])},
    "noParcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払う"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「処理を完了してください"])},
    "qrDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その後、下の「閉じる」をクリックしてください」"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
    "successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文のキャンセルに成功しました"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文をキャンセルできません"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金を受けるには、管理者に連絡してください"])},
    "statuses": {
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払済み"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発送済み"])},
      "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品を受け取りました"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル済み"])}
    }
  },
  "latestReceive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医師が最近処方した薬"])},
    "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての商品をカートに追加する"])}
  },
  "filter": {
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリで検索する"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリアする"])},
    "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格範囲"])},
    "priceRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格範囲（バーツ）"])},
    "inputLowestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低価格を入力する"])},
    "inputHighestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高価格を入力する"])},
    "priceFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から"])},
    "priceTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まで"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター"])},
    "sortLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["並べ替え"])},
    "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細検索"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引"])},
    "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売数"])},
    "numVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閲覧数"])},
    "tab": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
      "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気商品"])},
      "numVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閲覧数"])}
    }
  },
  "product": {
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問い合わせる"])},
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに追加する"])},
    "buyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ購入する"])}
  },
  "laserService": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レーザーサービス"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約する"])},
    "reserverTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約者の情報"])},
    "packageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パッケージ"])},
    "reserverName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前 - 姓"])},
    "reserverPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "getOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP を受け取る"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医師の予約"])},
    "fillOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP コードを入力する"])},
    "sendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["システムから OTP コードが電話番号に送信されました"])},
    "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約が完了しました"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医師の予約が完了しました"])},
    "memberTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号を確認してください"])},
    "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインページに移動する"])},
    "connectMakalin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号の確認ページに移動する"])},
    "successConnectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履歴のリンクに成功しました"])},
    "successConnectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おめでとうございます。100 コインを獲得しました"])},
    "backToReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約ページに戻る"])}
  }
}