<template>
  <div class="profile-view">
    <ProfileCard />
    <MyCoin class="my-coin" />
    <div class="menu-wrapper">
      <div v-for="m in menu" :key="m.pathName" class="menu" @click="goTo(m.pathName)">
        <span v-if="m.icon != 'coinlogo'">
        <img v-if="m.icon" :src="require(`@/assets/icons/${m.icon}.svg`)" :alt="m.icon" class="icon">
      </span>
      <span v-else>
        <img v-if="m.icon" :src="require(`@/assets/icons/${m.icon}.png`)" :alt="m.icon" class="iconpng">

      </span>
        <p class="menu-name">
          {{ t(`profileMenu.${m.name}`) }}
        </p>
        <img src="@/assets/icons/next.svg" alt="next" class="next">
      </div>
    </div>
    <span class="version">Version {{ version }}</span>
    <div class="button-container">
      <button class="add-friend" @click="openLink">
        <img class="icon-line" src="@/assets/icons/line.png" alt="logoApp" />
        {{ t('addfriend') }}
      </button>
    </div>
    <MButton v-if="user" outline-error class="mt-a" @click="signout">
      {{ t('signout') }}
    </MButton>

    


  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
// import liff from '@line/liff'

import ProfileCard from '@/components/ProfileCard'
import MyCoin from '@/components/MyCoin'
import MButton from '@/components/Form/MButton.vue'

export default {
  name: 'MyProfile',
  components: {
    ProfileCard,
    MyCoin,
    MButton
},
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    /** constant */
    const version = process.env.VUE_APP_VERSION
    const menu = [
       {
         name: 'referral',
         icon: 'coinlogo',
         pathName: 'Referral',
      },
      {
        name: 'myHistory',
        icon: 'cart',
        pathName: 'MyOrders',
      },
      {
        name: 'address',
        icon: 'pin',
        pathName: 'MyAddresses',
      },
      {
        name: 'favorite',
        icon: 'favorite',
        pathName: 'FavoriteProducts',
      },
      {
        name: 'myDrugs',
        icon: 'my-drugs',
        pathName: 'MyContinueDrugs',
      },
      // {
      //   name: 'bankAccount',
      //   icon: 'money',
      //   pathName: 'bankAccount',
      // },
      // {
      //   name: 'helpService',
      //   pathName: 'helpService',
      // },
      // {
      //   name: 'about',
      //   pathName: 'about',
      // },
    ]

    /** computed */
    const user = computed(() => store.getters['user/getUserProfile'])
    
    /** method */
    const goTo = path => router.push({ name: path })
    const signout = () => {
      store.commit('user/RESET_USER')
      // liff.init({
      //     liffId: process.env.VUE_APP_LINE_LIFE_ID
      //   }).then(() => {
      //     const isLoggedIn = liff.isLoggedIn()
      //     if (isLoggedIn) {
      //       liff.logout()
      //     }
      //     location.reload()
      //   })
    }

    onMounted(() => {
      store.dispatch('user/fetchGetUserProfile')
    })
    const openLink = () => {
      window.open('https://lin.ee/jr5To5P', '_blank');
    };

    return {
      t,
      menu,
      goTo,
      user,
      signout,
      version,
      openLink
    }
  }
}
</script>

<style lang="scss" scoped>

.profile-view {
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 76px;
}

.my-coin {
  margin: 24px 0;
}

.menu {
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #F2F2F2;
  margin-bottom: 16px;
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }
}

.icon {
  margin-right: 12px;
}

.iconpng {
  margin-right: 12px;
  width: 24px;
  height: 24px;
}

.menu-name {
  font-size: 1.2rem;
}

.next {
  margin-left: auto;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  margin-top: -10px;
}

.add-friend {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 45px;
  border-radius: 8px;
  background-color: #00B900;
  border: none;
  color: white;
  padding: 0 16px;
}

.icon-line {
  width: 50px;
  height: 50px;
}


.version {
  font-size: 10px;
  margin: 8px auto;
  color: var(--grey-color)
}
</style>
