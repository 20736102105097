export default {
  "makalin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มากาลิน"])},
  "logInToPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเข้าระบบเพื่อซื้อสินค้า...."])},
  "LogInWithName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with username "])},
  "LogInWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with phone number "])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "addfriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเพื่อน"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยินดีต้อนรับ"])},
  "notFoundProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบสินค้า"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไทย"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อังกฤษ"])},
  "soldAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ขายแล้ว ", _interpolate(_list(0)), " ชิ้น"])},
  "goToStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไปยังร้าน"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากระบบ"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้อนกลับ"])},
  "changeProfileImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนภาพโปรไฟล์"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชาย"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หญิง"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้ใช้งาน"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
  "getOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับ OTP"])},
  "notFoundPhoneInSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบเบอร์โทรศัพท์นี้ในระบบ"])},
  "pleaseContactAddmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อ admin เพื่อขออัพเดทเบอร์โทร"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หรือ"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขโทรศัพท์"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยัน"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
  "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งรหัสผ่านใหม่"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่านใหม่"])},
  "reNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันรหัสผ่านใหม่"])},
  "loginFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเข้าสู่ระบบก่อน"])},
  "successUpdateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัพเดตข้อมูลสำเร็จ"])},
  "successUpdatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนรหัสผ่านสำเร็จ"])},
  "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาขา"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตกลง"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตกลง"])},
  "backToMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับสู่หน้าหลัก"])},
  "baht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บาท"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไข"])},
  "didntGetOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้รับ OTP ?"])},
  "otpCounter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("second")), " วินาที"])},
  "resendOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กดที่นี่เพื่อส่ง OTP อีกครั้ง"])},
  "goToVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันเบอร์โทรศัพท์เพื่อรับ 100 เหรียญ"])},
  "collectCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สะสม coins เพื่อเป็นส่วนลดสินค้า"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พิมพ์ค้นหาที่นี่..."])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอสักครู่"])},
  "moreDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่านเพิ่มเติม"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อน"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำการคัดลอกไปยังคลิปบอร์ดเรียบร้อย"])},
  "downloadQrCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กดที่นี่เพื่อนดาวน์โหลด QR Code"])},
  "clickToPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากไม่นำไปหน้าชำระเงิน ให้คลิกที่นี่ หรือตรวจสอบอีกครั้งว่าได้ติดตั้ง Aplication SCB อยู่หรือไม่"])},
  "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีบางอย่างผิดพลาด"])},
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกี่ยวกับเรา"])}
  },
  "session": {
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่นหมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง"])}
  },
  "myContinueDrugs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาของฉัน"])},
    "howToUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีใช้ยา"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปแบบของยา"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขนาดบรรจุ"])},
    "seeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูข้อมูลยา"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณยังไม่มีรายการยา"])},
    "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช้า"])},
    "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลางวัน"])},
    "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เย็น"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ก่อนนอน"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
    "usingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาที่กำลังใช้"])},
    "unusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาที่หมดแล้ว"])},
    "setUnusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกปรับรายการยาเป็น 'ยาที่หมดแล้ว'"])},
    "setUsingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกปรับรายการยาเป็น 'ยาที่กำลังใช้'"])}
  },
  "profile": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นามสกุล"])},
    "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อที่จะแสดง"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทร"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเกิด"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพศ"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำหนัก (กก.)"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนสูง (ซม.)"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขโปรไฟล์"])},
    "pleaseFillEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกอีเมล"])},
    "pleaseFillBoD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกวันเกิด"])},
    "pleaseFillHegiht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกส่วนสูง"])},
    "pleaseFillWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกน้ำหนัก"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
    "allergicDrug": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แพ้ยา"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูล"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุชื่อยาที่แพ้"])}
    },
    "congenitalDisease": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โรคประจำตัว"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูล"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุชื่อโรคประจำตัว"])}
    },
    "personalMedicine": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาประจำตัว"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูล"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุชื่อยาประจำตัว"])}
    }
  },
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรดชำระภายใน"])},
  "term": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นโยบายความเป็นส่วนตัว"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับทราบและยอมรับ"])}
  },
  "creditCard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระด้วยบัตร Credit / Debit"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการต่อ"])},
    "placeholder": {
      "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดด/ปป"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อบนบัตร"])},
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXXX XXXX XXXX XXXX"])}
    },
    "label": {
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลขบัตร"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อบนบัตร"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัตรหมดอายุ"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])}
    },
    "errs": {
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลขบัตรจะต้องมี 16 หลักเท่านั้น"])},
      "expFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัตรหมดอายุจะต้องกรอกด้วย ดด/ปป"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือนหมดอายุไม่ถูกต้อง"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปีหมดอายุไม่ถูกต้อง"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV จะต้องกรอกด้วยตัวเลข 3 หลัก"])},
      "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีบางอย่างผิดพลาด"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกชื่อบนบัตร"])},
      "cardExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัตรของคุณหมดอายุ"])},
      "cardInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลบัตรไม่ถูกต้อง"])}
    }
  },
  "cancellation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกคำสั่งซื้อ"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกสาเหตุการยกเลิกคำสั่งซื้อ"])}
  },
  "myCoupon": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คูปองส่วนลด"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณยังไม่มีคูปองส่วนลด"])},
    "useCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้คูปอง"])},
    "selectCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกคูปอง"])},
    "card": {
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนลด"])},
      "noMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีขั้นต่ำ"])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขั้นต่ำ"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานได้ถึงวันที่"])},
      "noExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีวันหมดอายุ"])}
    }
  },
  "myFavorite": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการโปรด"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสินค้าโปรดแล้ว"])},
    "successRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นำสินค้าโปรดออกแล้ว"])}
  },
  "verifyUser": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันเบอร์โทรศัพท์"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกเบอร์"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อทำการยืนยันตัวตน"])},
    "getFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับฟรี"])},
    "coins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " เหรียญ"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก OTP"])}
  },
  "loginWithPhone": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบด้วยเบอร์โทรศัพท์"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกเบอร์ (ที่เชื่อมต่อกับ Makalin Clinic)"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อทำการยืนยันตัวตน"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก OTP"])}
  },
  "forgetPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลืมรหัสผ่าน"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกเบอร์ (ที่เชื่อมต่อกับ Makalin Clinic)"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อทำการยืนยันตัวตน"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก OTP"])}
  },
  "loginWithUsername": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบด้วยชื่อผู้ใช้งาน"])}
  },
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าหลัก"])},
    "myDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาของฉัน"])},
    "myCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตะกร้า"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรไฟล์"])}
  },
  "profileMenu": {
    "myHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การซื้อของฉัน"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชวนเพื่อนสะสมคะแนน"])},
    "myDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาต่อเนื่อง"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการที่อยู่"])},
    "bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลบัญชีธนาคาร"])},
    "helpService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ศูนย์ช่วยเหลือ"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกี่ยวกับฉัน"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการโปรด"])}
  },
  "myDiaryDrugs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาประจำของฉัน"])},
    "drugGraphTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนยาที่ใช้บ่อย"])},
    "continueDrugsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 อันดับยาที่ใช้ต่อเนื่อง"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณยังไม่มีรายการยา"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
    "recommendTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สินค้าแนะนำ"])},
    "frequencyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สินค้าที่ซื้อบ่อย"])},
    "latestPurchaseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สินค้าที่ซื้อล่าสุด"])},
    "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสินค้าทั้งหมดไปยังตะกร้า"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกทั้งหมด"])},
    "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นำออกทั้งหมด"])},
    "addSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสินค้าที่เลือกไปยังตะกร้า"])}
  },
  "myCart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Cart"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณยังไม่มีสินค้าในตะกร้า"])},
    "shopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช้อปตอนนี้"])},
    "suggestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซื้อด้วยกันประหยัดกว่า"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสินค้าลงตะกร้าแล้ว"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวมค่าสินค้า"])},
    "excludeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่รวมค่าส่ง"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สั่งซื้อสินค้า"])}
  },
  "checkout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สรุปคำสั่งซื้อ"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีการจัดส่ง"])},
    "sendByPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดส่งทางไปรษณีย์"])},
    "takeaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับด้วยตนเองที่ร้านสาขา"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการสินค้า"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอดรวม"])},
    "sumTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าสินค้า (รวม VAT 7%)"])},
    "shippingFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าจัดส่ง"])},
    "sumDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนลดคูปอง"])},
    "sumVat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["VAT ", _interpolate(_named("percent")), "%"])},
    "sumGrandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวมทั้งหมด"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางการชำระเงิน"])},
    "emailDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกอีเมลของคุณ"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
    "referalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสเชิญ"])},
    "placeHolderReferalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกรหัสเชิญ"])},
    "acceptParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันได้ยอมรับในนโยบายการคืนเงินและสินค้า\n(คลิกเพื่ออ่านรายละเอียดเพิ่มเติม)\nเบอร์โทรติดต่อ: 064-181-6580-2"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จ่ายเงิน"])},
    "changeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนที่อยู่จัดส่ง"])},
    "goToAddAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไปเพิ่มที่อยู่"])},
    "pleaseSelectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเพิ่มที่อยู่จัดส่ง"])},
    "pleaseSelectPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกวิธีการชำระเงิน"])}
  },
  "myAddresses": {
    "changeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนที่อยู่จัดส่ง"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมุดที่อยู่"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณยังไม่ได้บันทึกที่อยู่"])},
    "mainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่หลัก"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างที่อยู่ใหม่เรียบร้อยแล้ว"])},
    "successUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขที่อยู่เรียบร้อยแล้ว"])},
    "successDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบที่อยู่สำเร็จ"])}
  },
  "addAddresses": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มที่อยู่"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขที่อยู่"])},
    "inCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในประเทศ"])},
    "abroad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่างประเทศ"])},
    "form": {
      "receiveName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้รับ"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทรศัพท์"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทที่อยู่"])},
      "addressNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดที่อยู่ (บ้านเลขที่ ซอย ถนน ฯ)"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จังหวัด"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อำเภอ/เขต"])},
      "subDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตำบล/แขวง"])},
      "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสไปรษณีย์"])},
      "setMainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้เป็นที่อยู่หลัก"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
      "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้าม"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บ้าน"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ทำงาน"])}
    }
  },
  "allProducts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สินค้าทั้งหมด"])}
  },
  "referral": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชวนเพื่อนสะสมคะแนน"])},
    "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสเชิญ"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอก"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยัน"])},
    "myscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนของเรา"])},
    "friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนเพื่อน"])},
    "inputRefCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกรหัสเชิญ"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประวัติการสะสมคะแนน"])},
    "useCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชวนเพื่อนสะสมแต้ม"])},
    "useQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สแกนเพื่อสะสมแต้ม"])},
    "useCodeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณได้รับคะแนนจากเพื่อนใส่รหัสของคุณ"])},
    "useQRCodeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณได้รับคะแนนจากการสแกน QR CODE"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" คะแนน"])}
  },
  "order": {
    "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีบางอย่างผิดพลาด"])},
    "successPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเงินเรียบร้อยแล้ว"])},
    "errorPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเงินไม่สำเร็จ กรุณาจ่ายเงินใหม่อีกครั้ง"])},
    "creditCardErrorPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเงินไม่สำเร็จ กรุณาทำรายการใหม่อีกครั้ง"])},
    "orderAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซื้ออีกครั้ง"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการคำสั่งซื้อ"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order ID"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดส่ง"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระผ่าน"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอดรวม"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำสั่งซื้อ"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางการชำระเงิน"])},
    "sumTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวมค่าสินค้า"])},
    "shippingFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าจัดส่ง"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนลดคูปอง"])},
    "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวมการสั่งซื้อ"])},
    "gainPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณจะได้คะแนนสะสม"])},
    "sendByPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดส่งทางไปรษณีย์"])},
    "parcelTrackNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลขติดตามพัสดุ"])},
    "itemTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการสินค้า"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวน"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชิ้น"])},
    "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกคำสั่งซื้อ"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับสู่หน้าหลัก"])},
    "noParcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีเลขติดตามพัสดุ"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จ่ายเงิน"])},
    "qrDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาทำรายการให้สำเร็จ\nหลังจากนั้นกดปิดด้านล่าง"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])},
    "successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกคำสั่งซื้อสำเร็จ"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถทำการยกเลิกคำสั่งซื้อได้"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาติดต่อ admin เพื่อรับเงินคืน"])},
    "statuses": {
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเงินแล้ว"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดส่งแล้ว"])},
      "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ได้รับสินค้าแล้ว"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกแล้ว"])}
    }
  },
  "latestReceive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาที่หมอจ่ายล่าสุด"])},
    "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสินค้าทั้งหมดไปยังตะกร้า"])}
  },
  "filter": {
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาด้วยประเภท"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล้างค่า"])},
    "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงราคา"])},
    "priceRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงราคา(฿)"])},
    "inputLowestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใส่ราคาต่ำสุด"])},
    "inputHighestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใส่ราคาสูงสุด"])},
    "priceFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จาก"])},
    "priceTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถึง"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรอง"])},
    "sortLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียงโดย"])},
    "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาแบบละเอียด"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ราคา"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนลด"])},
    "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนยอดขาย"])},
    "numVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผู้เข้าชม"])},
    "tab": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนลด"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ราคา"])},
      "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สินค้าขายดี"])},
      "numVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอดผู้ชม"])}
    }
  },
  "product": {
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สอบถาม"])},
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มในตะกร้า"])},
    "buyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซื้อตอนนี้"])}
  },
  "laserService": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริการเลเซอร์"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จองคิว"])},
    "reserverTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลผู้จอง"])},
    "packageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แพกเกจ"])},
    "reserverName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ - นาสกุล"])},
    "reserverPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทรศัพท์"])},
    "getOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับ OTP"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จองคิวแพทย์"])},
    "fillOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกรหัส OTP"])},
    "sendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบได้ส่ง SMS รหัส OTP ไปยังเบอร์"])},
    "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จองสำเร็จ"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ท่านได้ทำการจองคิวแพทย์สำเร็จแล้ว"])},
    "memberTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณายืนยันเบอร์โทรศัพท์ของท่าน"])},
    "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไปหน้าเข้าสู่ระบบ"])},
    "connectMakalin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไปหน้ายืนยันเบอร์โทรศัพท์"])},
    "successConnectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมประวัติสำเร็จ"])},
    "successConnectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยินดีด้วยคุณได้คะแนน 100 coins"])},
    "backToReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับไปหน้าจองคิว"])}
  }
}