import api from '@/api'

const getMyContinueDrugs = async () => {
  const result = await api.get('/api/user/my_continue_drug')
  
  return result
}

const getHowToUseMyContinueDrugs = async () => {
  const result = await api.get('/api/user/my_continue_drug/how_to_use')
  
  return result
}

const getProfileUser = async () => {
  const result = await api.get('/api/user/whoami')
  
  return result
}

const updateUser = async (body) => {
  const result = await api.post('/api/user/update_user', body)
  
  return result
}

const updateMakalinPhone = async (phone) => {
  const result = await api.post('/api/user/makalin/update_phone', { mobile_no: phone })
  
  return result
}

const updateAvatar = async (file) => {
  const formData = new FormData()
  formData.append('file', file)
  const result = await api.post('/api/user/upload_avatar', formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
  
  return result
}

const loginWithUsername = async (username, password) => {
  const result = await api.post('/api/user/login', {
    username,
    password,
  }, {
    noRequiredToken: true
  })

  return result
}

const loginWithLine = async (lineAccessToken, s) => {
  const body = {
    line_access_token: lineAccessToken,
  }
  if (s) body.short_url_token = s
  const result = await api.post('/api/user/login/line', body, {
    noRequiredToken: true,
  })

  return result
}

const getOTP = async (phone, branchId) => {
  const result = await api.post('/api/user/login/phone', {
    mobile_no: phone,
    branch_id: branchId
  })

  return result
}

const checkOrder = async (userId) => {
  const result = await api.get('/api/order/check_order?user_id='+userId)
  return result
}

const addCoinRefferal = async (userId, coin) => {
  const result = await api.post('/api/user/add_referral_reward/', {
    "user_id": userId,
    "coin_amount": coin
  })

  return result
}

const checkQr = async (v) => {
  const result = await api.post('/api/user/check_scan_qr/', {
    "v": v
  })

  return result
}

const checkRefUser = async (userId) => {
  const result = await api.post('/api/user/check_referral_user/', {
    "user_id": userId
  })

  return result
}

const checkLineUser = async (userId) => {
  const result = await api.post('/api/user/check_line_user/', {
    "user_id": userId
  })

  return result
}





const verifyOTP = async (phone, requestIdOtp) => {
  const result = await api.post('/api/user/login/phone/verify', {
    otp_code: phone,
    request_id: requestIdOtp,
  })

  return result
}

const getLaserOTP = async (phone) => {
  const result = await api.post('/api/user/laser_booking', {
    mobile_no: phone,
  })

  return result
}

const verifyLaserOTP = async (otp, requestIdOtp, productId, name) => {
  const result = await api.post('/api/user/laser_booking/verify', {
    otp_code: otp,
    request_id: requestIdOtp,
    product_id: productId,
    name,
  })

  return result
}

const getVerifyUserOTP = async (phone, branchId) => {
  const result = await api.post('/api/user/verify_makalin_user', {
    mobile_no: phone,
    branch_id: branchId
  })

  return result
}

const verifyUserOTP = async (phone, requestIdOtp) => {
  const result = await api.post('/api/user/verify_makalin_user_otp', {
    otp_code: phone,
    request_id: requestIdOtp,
  })

  return result
}

const getForgetPasswordOTP = async (phone) => {
  const result = await api.post('/api/user/forgot_password', {
    mobile_no: phone,
  })

  return result
}

const verifyForgetPasswordOTP = async (phone, requestIdOtp) => {
  const result = await api.post('/api/user/forgot_password/verify', {
    otp_code: phone,
    request_id: requestIdOtp,
  })

  return result
}

const setNewPassword = async (newpassword, reNewPassword, requestIdOtp) => {
  const result = await api.post('/api/user/forgot_password/set_password', {
    password: newpassword,
    re_password: reNewPassword,
    request_id: requestIdOtp,
  })

  return result
}

const getAddresses = async () => {
  const result = await api.get('/api/user/address')
  
  return result
}

const getAddress = async (id) => {
  const result = await api.get(`/api/user/address/${id}`)
  
  return result
}

const addNewAddress = async (body) => {
  const result = await api.post('/api/user/address/add', body)
  
  return result
}

const updateAddress = async (id, body) => {
  const result = await api.patch(`/api/user/address/${id}`, body)
  
  return result
}

const deleteAddress = async (id) => {
  const result = await api.delete(`/api/user/address/${id}`)
  
  return result
}

const getBranches = async () => {
  const result = await api.get('/api/user/branch')
  
  return result
}

const getTermAndPrivacy = async () => {
  const result = await api.get('/api/user/term')
  
  return result
}

const acceptPrivacy = async () => {
  const result = await api.post('/api/user/term/accept_privacy')
  
  return result
}

const getProvince = async () => {
  const result = await api.get('/api/user/province')

  return result
}

const getDistrict = async (provinceCode) => {
  const result = await api.get(`/api/user/district?province_code=${provinceCode}`)
  result.sort((a, b) => a.district_name.localeCompare(b.district_name));

  // Now the 'result' array is sorted by 'district_name' in ascending order
  console.log(result);

  return result
}

const getSubDistrict = async (districtCode) => {
  const result = await api.get(`/api/user/sub_district?district_code=${districtCode}`)
  result.sort((a, b) => a.sub_district_name.localeCompare(b.sub_district_name));
  console.log(result);

  return result
}

export default {
  getMyContinueDrugs,
  getHowToUseMyContinueDrugs,
  loginWithUsername,
  getProfileUser,
  getOTP,
  verifyOTP,
  updateUser,
  getAddress,
  getAddresses,
  addNewAddress,
  loginWithLine,
  getForgetPasswordOTP,
  verifyForgetPasswordOTP,
  setNewPassword,
  deleteAddress,
  updateAddress,
  getBranches,
  updateAvatar,
  getTermAndPrivacy,
  acceptPrivacy,
  getVerifyUserOTP,
  verifyUserOTP,
  getProvince,
  getDistrict,
  getSubDistrict,
  getLaserOTP,
  verifyLaserOTP,
  updateMakalinPhone,
  addCoinRefferal,
  checkOrder,
  checkRefUser,
  checkQr,
  checkLineUser
}
