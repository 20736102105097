<template>
  <div class="wrapper">
    <HomeTopBar has-background />
    <ImageSlider :items="banners" />
    <div class="main-wrapper">
      <MyCoin class="my-coin" />
      <!-- <ProductHeaderMenu title="Flash Deal" :end="end" seeAll />
      <div class="horizontal-products-wrapper">
        <MiniProductCard
          v-for="i in 3"
          :key="i"
          :title="'Double UV Defense 30g. (ซับมัน)'"
          :price="150"
          :sold="5"
          :tag="'https://...'"
          :status="i % 2 === 0 ? 'new' : 'hot'"
          @click="$router.push(`/product/${i + 1}`)"
        />
      </div> -->
      <div class="categories-wrapper">
        <div
          v-for="(category) in categories"
          :key="category.id"
          class="category-item"
        >
          <div class="img-wrapper" :class="{ 'jiggle-animation': category.id === 10 }">
            <img :src="category.icon" :alt="`icon-${category.id}`" @click="goToCategory(category)">
          </div>
          <div class="category-title">{{ changeLanguage(category.title) }}</div>
        </div>
      </div>
      <!-- <ProductHeaderMenu title="Recommended Laser and Services" /> -->
      <ProductHeaderMenu title="Recommended for You" />
      <div v-if="recommendProducts" class="products-wrapper">
        <ProductCard
          v-for="(product, index) in recommendProducts"
          :key="index"
          :title="product.name"
          :price="numberWithCommas(product.price)"
          :sold="numberWithCommas(product.sold)"
          :thumbnail="product.thumbnail"
          @click="goToProduct(product)"
        />
      </div>
      <MiniLoading v-else />
      <ProductHeaderMenu title="Recently Added" />
      <div v-if="recentProducts" class="products-wrapper">
        <ProductCard
          v-for="(product, index) in recentProducts"
          :key="index"
          :title="product.name"
          :price="numberWithCommas(product.price)"
          :sold="numberWithCommas(product.sold)"
          :thumbnail="product.thumbnail"
          @click="goToProduct(product)"
        />
      </div>
      <MiniLoading v-else />
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import numberWithCommas from '@/utils/numberWithCommas'
// import laserCategoryId from '@/constants/laserCategoryId'

import MyCoin from '@/components/MyCoin'
import HomeTopBar from '@/components/HomeTopBar'
import ImageSlider from '@/components/ImageSlider'
import MiniLoading from '@/components/MiniLoading'
import ProductCard from '@/components/ProductCard'
import ProductHeaderMenu from '@/components/ProductHeaderMenu'
// import MiniProductCard from '@/components/MiniProductCard'

export default {
  name: 'MainPage',
  components: {
    MyCoin,
    HomeTopBar,
    ImageSlider,
    MiniLoading,
    ProductCard,
    ProductHeaderMenu,
    // MiniProductCard,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    
    /** constant */
    const end = dayjs().add(1, 'day')
    
    /** computed */
    const banners = computed(() => store.state.frontpage.data.banners)
    const recentProducts = computed(() => store.state.frontpage.data.recentProducts)
    const recommendProducts = computed(() => store.state.frontpage.data.recommendProducts)
    const categories = computed(() => store.state.frontpage.data.categories)

    /** method */
    const goToProduct = (product) => router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}`)
    const goToCategory = (category) => {
      // TODO check if laser or category
      // if (category.id === laserCategoryId) {
      //   router.push(`/laser-service`)
      // }
      // else {
        router.push(`/product/category/${category.id}`)
      // }
    }

    const changeLanguage = (text) => {
      const currentLanguage = localStorage.getItem('language');
      console.log(currentLanguage)
      const wordsTH = ["เลเซอร์ และบริการอื่นๆ", "ก่อนล้างหน้า", "ล้างหน้า", "ทาสิว", "กันแดด", "บำรุงผิว", "ปรับสภาพผิว", "อื่นๆ", "แชมพู", "แก้คัน/แต้มหลุม"]
      const wordsEN = ["Laser and other services", "Before face wash", "Facial cleanser", "Acne", "Sun care", "Skin care", "Toner", "Others", "Shampoo", "Serum"]
      const wordsCN = ["激光和其他服务", "洁面之前", "洁面", "祛痘", "防晒", "护肤", "调理肌肤", "其他", "洗发水", "止痒，点痣"]
      const wordsJP = ["レーザーとその他のサービス", "洗顔前", "洗顔", "ニキビ治療", "日焼け止め", "スキンケア", "コンディショニング", "その他", "シャンプー", "かゆみ止め|スポット治療"]
      const wordsKM = ["ឡាស៊ី និងសេវាផ្សេងទៀត", "ថ្នាំផ្អាប់", "សាប៊ូលាងមុខ", "ថ្នាំលាបមុន", "ឡេការពារកំដៅថ្ងៃ", "ថ្នាំថែរក្សាស្បែក", "ថ្នាំបំប៉នស្បែក", "ផ្សេងៗ", "សាប៊ូកក់សក់", "ថ្នាំបំបាត់រមាស់ | ថ្នាំបណ្តក់ប្រហោង"]
      for (let i = 0; i < wordsTH.length; i++) {
        if (text === wordsTH[i]) {
          if (currentLanguage === 'cn') {
            return wordsCN[i]
          } else if (currentLanguage === 'jp') {
            return wordsJP[i]
          } else if (currentLanguage === 'km') {
            return wordsKM[i]
          }
        }
      }
      for (let i = 0; i < wordsEN.length; i++) {
        if (text === wordsTH[i]) {
          if (currentLanguage === 'cn') {
            return wordsCN[i]
          } else if (currentLanguage === 'jp') {
            return wordsJP[i]
          } else if (currentLanguage === 'km') {
            return wordsKM[i]
          }
        }
      }
      return text
    }

    /** mounted */
    onMounted(() => {
      store.dispatch('frontpage/fetchFrontpageData')
      store.dispatch('user/fetchGetUserProfile')
    })

    return {
      end,
      banners,
      recentProducts,
      recommendProducts,
      goToProduct,
      goToCategory,
      categories,
      numberWithCommas,
      changeLanguage
    }
  }
}
</script>

<style lang="scss" scoped>

.main-wrapper {
  position: relative;
  padding: 16px 16px 72px;
  display: flex;
  flex-direction: column;
}

.products-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 24px;
}

.horizontal-products-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 24px;
}

.my-coin {
  margin-bottom: 16px;
}

.categories-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:active {
    opacity: 0.7;
  }
  .category-title {
    margin-top: 4px;
    text-align: center;
  }
  .img-wrapper {
    width: 42px;
    height: 42px;
    background-color: #a2d1d2;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > img {
      width: 100%;
    }
  }
  @keyframes jiggle {
    0% { transform: rotate(0deg); }
    7% { transform: rotate(-5deg);}
    14% { transform: rotate(5deg) scale(1.1);}
    21% { transform: rotate(-5deg); }
    28% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
  }

  .jiggle-animation {
      /* Applying the jiggle animation with a pause */
      animation: jiggle 3s ease-in-out infinite;
      filter: hue-rotate(-130deg) brightness(1.2);
  } 
}
</style>
