export default {
  "makalin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makalin"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "logInToPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in to purchase products...."])},
  "LogInWithName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with username "])},
  "LogInWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with phone number "])},
  "notFoundProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product is not found"])},
  "addfriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add friend"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TH"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
  "soldAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " sold"])},
  "goToStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to store"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "changeProfileImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change profile image"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "getOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
  "notFoundPhoneInSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ํThe phone number is not found."])},
  "pleaseContactAddmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact admin to update your phone number."])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OR"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new password"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "reNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-new password"])},
  "loginFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in first"])},
  "successUpdateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated profile"])},
  "successUpdatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated password"])},
  "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "backToMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to main page"])},
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining"])},
  "baht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baht"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "didntGetOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't get OTP."])},
  "otpCounter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("second")), " second[s]"])},
  "resendOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to resend OTP."])},
  "goToVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to verrify to get 100 coins"])},
  "collectCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect coins for discount"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type here to search..."])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "moreDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully copy to clipboard."])},
  "downloadQrCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to download QR code"])},
  "clickToPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are not redirected to the payment page, click here or check again if the SCB Application is installed"])},
  "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong !"])},
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])}
  },
  "session": {
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expired, please login again"])}
  },
  "myContinueDrugs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Continue Drugs"])},
    "howToUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of drug"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net weight"])},
    "seeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See product"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No any continue drugs"])},
    "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning"])},
    "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afternoon"])},
    "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evening"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before bed"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "usingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาที่กำลังใช้"])},
    "unusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาที่หมดแล้ว"])},
    "setUnusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกปรับรายการยาเป็น 'ยาที่หมดแล้ว'"])},
    "setUsingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกปรับรายการยาเป็น 'ยาที่กำลังใช้'"])}
  },
  "profile": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight (kg.)"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height (cm.)"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "pleaseFillEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your email"])},
    "pleaseFillBoD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your birth of date"])},
    "pleaseFillHegiht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your height"])},
    "pleaseFillWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your weight"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "allergicDrug": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergic Drugs"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter medicine's name"])}
    }
  },
  "term": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
  },
  "creditCard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay by Credit / Debit Card"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "placeholder": {
      "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM/YY"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name on Card"])},
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXXX XXXX XXXX XXXX"])}
    },
    "label": {
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name on Card"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])}
    },
    "errs": {
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The card number should be exactly 16 numbers"])},
      "expFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The format of expired should be MM/YY"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The expired month is not valid"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The expired year is not valid"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The format of CVV should be 3 numbers"])},
      "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong !"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill name on card"])},
      "cardExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card id expired"])},
      "cardInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ํYour card's information is invalid"])}
    }
  },
  "cancellation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Cancellation"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please tell us the reason why cancelled"])}
  },
  "myCoupon": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No any coupon"])},
    "useCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use coupon"])},
    "selectCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "card": {
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
      "noMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No minimum"])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
      "noExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No expired"])}
    }
  },
  "myFavorite": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite List"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Added"])},
    "successRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Removed"])}
  },
  "verifyUser": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Phone"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your mobile phone"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for verification."])},
    "getFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get"])},
    "coins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " coin[s]"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill OTP"])}
  },
  "loginWithPhone": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Phone"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your mobile phone (Already register with Makalin)"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for verification."])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill OTP"])}
  },
  "forgetPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Phone"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your mobile phone (Already register with Makalin)"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for verification."])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill OTP"])}
  },
  "loginWithUsername": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with username"])}
  },
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "myDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Drugs"])},
    "myCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
  },
  "profileMenu": {
    "myHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My purchase history"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral code"])},
    "myDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My continue drugs"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account"])},
    "helpService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help service"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite list"])}
  },
  "myDiaryDrugs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My diary drugs"])},
    "drugGraphTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostly used drugs"])},
    "continueDrugsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 Mostly continue drugs"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No item"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "recommendTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommend"])},
    "frequencyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency"])},
    "latestPurchaseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Purchase"])},
    "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add all products to cart"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
    "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove All"])},
    "addSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add selected products to cart"])},
    "usingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาที่กำลังใช้"])},
    "unusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาที่หมดแล้ว"])},
    "setUnusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกปรับรายการยาเป็น 'ยาที่หมดแล้ว'"])},
    "setUsingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกปรับรายการยาเป็น 'ยาที่กำลังใช้'"])}
  },
  "myCart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My cart"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No item in your cart"])},
    "shopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop Now"])},
    "suggestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy together, less price"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Added"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "excludeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipping excluded"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])}
  },
  "checkout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping method"])},
    "sendByPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "takeaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At shop"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "sumTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum total (Includes 7% VAT)"])},
    "shippingFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping fee"])},
    "sumDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "sumVat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["VAT ", _interpolate(_named("percent")), "%"])},
    "sumGrandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "emailDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your email"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "referalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referal code"])},
    "placeHolderReferalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill referal code"])},
    "acceptParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept money and products refund policy\n(Click for more detail)\nTel: 064-181-6580-2"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "changeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change address"])},
    "goToAddAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to add address"])},
    "pleaseSelectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add your address."])},
    "pleaseSelectPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a payment method to continue."])}
  },
  "myAddresses": {
    "changeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Address"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Addresses"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No any Address"])},
    "mainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default address"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Added"])},
    "successUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Updated"])},
    "successDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Deleted"])}
  },
  "addAddresses": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Address"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Address"])},
    "inCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domestic"])},
    "abroad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abroad"])},
    "form": {
      "receiveName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address type"])},
      "addressNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address No."])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "subDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-district"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
      "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zipcode"])},
      "setMainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set to default address"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])}
    }
  },
  "allProducts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All products"])}
  },
  "referral": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral code"])},
    "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral code"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friend's"])},
    "myscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My score"])},
    "inputRefCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input referral code"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "useCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friend use referral code"])},
    "useQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan to collect points"])},
    "useCodeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You get points from friends entering your code"])},
    "useQRCodeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have earned points from scanning the QR code"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" point"])}
  },
  "order": {
    "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong !"])},
    "successPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully payment"])},
    "errorPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while payment"])},
    "creditCardErrorPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while payment"])},
    "orderAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-order"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My orders"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order ID"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping method"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order No."])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "sumTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum total"])},
    "shippingFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping fee"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grand total"])},
    "gainPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received point"])},
    "sendByPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery by post"])},
    "parcelTrackNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking No."])},
    "itemTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item list"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unit(s)"])},
    "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel order"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
    "noParcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not get parcel yet"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "qrDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please do payment, then close this modal"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully cancelled"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot cancelled"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact admin for cash refund"])},
    "statuses": {
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In deliver"])},
      "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])}
    }
  },
  "latestReceive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Receive Drugs"])},
    "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add all to cart"])}
  },
  "filter": {
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "priceRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price range(฿)"])},
    "inputLowestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input lowest price"])},
    "inputHighestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input highest price"])},
    "priceFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "priceTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "sortLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold"])},
    "numVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of visits"])},
    "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced filter"])},
    "tab": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold"])},
      "numVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits"])}
    }
  },
  "product": {
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask"])},
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add To Cart"])},
    "buyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now"])}
  },
  "laserService": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laser Service"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve"])},
    "reserverTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your detail"])},
    "packageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])},
    "reserverName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "reserverPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "getOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "fillOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill OTP"])},
    "sendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System has sent OTP to"])},
    "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully reservation"])},
    "memberTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your phone"])},
    "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "connectMakalin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to makalin"])},
    "successConnectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
    "successConnectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulation! you've got 100 coins"])},
    "backToReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Reservation page"])}
  }
}