export default {
  "makalin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ម៉ាកាលីន"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" សូមស្វាគមន៍"])},
  "logInToPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមចូលក្នុងប្រព័ន្ធដើម្បីទិញផលិតផល...."])},
  "LogInWithName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូលក្នុងប្រព័ន្ធដោយប្រើឈ្មោះអ្នកប្រើប្រាស់"])},
  "LogInWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូលក្នុងប្រព័ន្ធដោយប្រើលេខទូរស័ព្ទ"])},
  "addfriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ថែមមិត្ត"])},
  "notFoundProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មិនមានឈ្មោះផលិតផល"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថៃ"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អង់គ្លេស"])},
  "soldAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["បានលក់ ", _interpolate(_list(0)), " "])},
  "goToStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូលទៅកាន់ហាង"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចាកចេញ"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថយក្រោយ"])},
  "changeProfileImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផ្លាស់ប្តូររូបភាពប្រវត្តិរូប"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រុស"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ស្រី"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រក្សាទុក"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះអ្នកប្រើប្រាស់"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ពាក្យសម្ងាត់"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូលក្នុងប្រព័ន្ធ"])},
  "getOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទទួល OTP"])},
  "notFoundPhoneInSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មិនមានលេខទូរស័ព្ទនេះក្នុងប្រព័ន្ធ"])},
  "pleaseContactAddmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមទាក់ទង admin ដើម្បីកែតម្រូវលេខទូរស័ព្ទ"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឬ"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខទូរស័ព្ទ"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជាក់"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បោះបង់"])},
  "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កំណត់ពាក្យសម្ងាត់ថ្មី"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ពាក្យសម្ងាត់​ថ្មី"])},
  "reNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចូលពាក្យសម្ងាត់ថ្មីម្តងទៀត"])},
  "loginFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមចូលទៅកាន់ប្រព័ន្ធជាមុនសិន"])},
  "successUpdateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បានធ្វើបច្ចុប្បន្នភាពប្រវត្តិរូបដោយជោគជ័យ"])},
  "successUpdatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បានធ្វើបច្ចុប្បន្នភាពពាក្យសម្ងាត់ដោយជោគជ័យ"])},
  "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សាខា"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជូន"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["យល់ព្រម"])},
  "backToMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ត្រឡប់ទៅទំព័រដើម"])},
  "baht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បាត"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កែសម្រួល"])},
  "didntGetOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មិនបានទទួល OTP ?"])},
  "otpCounter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("second")), " វិនាទី"])},
  "resendOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចុចត្រង់នេះដើម្បីផ្ញើរ OTP ម្តងទៀត"])},
  "goToVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផ្ទៀងផ្ទាត់លេខទូរស័ព្ទ ដើម្បីទទួលបាន 100 កាក់"])},
  "collectCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សន្សំ coins ដើម្បីទទួលបានការបញ្ចុះតម្លៃ"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["វាយពាក្យនៅត្រង់នេះដើម្បីស្វែងរក…"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមមេត្តារង់ចាំ"])},
  "moreDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អាន​បន្ថែម"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លាក់"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បានចម្លងទៅកាន់​clipboard​​ដោយជោគជ័យ"])},
  "downloadQrCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចុចត្រង់នេះដើម្បីទាញយក QR Code"])},
  "clickToPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រសិនបើមិនបានបញ្ជូនទៅទំព័រទូទាត់ប្រាក់ សូមចុចទីនេះ ឬពិនិត្យមើលម្ដងទៀតថាតើបានដំឡើងកម្មវិធី SCB ឬអត់។"])},
  "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មាន​អ្វីមួយ​មិន​ត្រឹមត្រូវ!"])},
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អំពី​​យើង"])}
  },
  "session": {
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផុតសុពលភាព សូមចូលទៅកាន់ប្រព័ន្ធម្តងទៀត"])}
  },
  "myContinueDrugs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្នាំរបស់ខ្ញុំ"])},
    "howToUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["របៀបប្រើថ្នាំ"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រភេទថ្នាំ"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បរិមាណ"])},
    "seeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មើលឯកសារថ្នាំ"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកមិនទាន់មានឈ្មោះថ្នាំ"])},
    "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ព្រឹក"])},
    "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្ងៃត្រង់"])},
    "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ល្ងាច"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មុនគេង"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទាំងអស់"])},
    "usingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្នាំកំពុងប្រើ"])},
    "unusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្នាំដែលអស់"])},
    "setUnusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ជ្រើសរើសប្តូរថ្នាំ\"ថ្នាំដែលអស់\""])},
    "setUsingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ជ្រើសរើសប្តូរថ្នាំ\"ថ្នាំដែលកំពុងប្រើ\""])}
  },
  "profile": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះ"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["នាមត្រកូល"])},
    "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះដែលត្រូវបង្ហាញ"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ៊ីមែល"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខទូរស័ព្ទ"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្ងៃខែឆ្នាំកំណើត"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ភេទ"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទម្ងន់ (គក.)"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កំពស់ (សម.)"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កែប្រវត្តិរូប"])},
    "pleaseFillEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញអ៊ីមែលរបស់អ្នក"])},
    "pleaseFillBoD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញថ្ងៃខែឆ្នាំកំណើតរបស់អ្នក"])},
    "pleaseFillHegiht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញកម្ពស់របស់អ្នក"])},
    "pleaseFillWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញទម្ងន់របស់អ្នក"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូលទៅកាន់"])},
    "allergicDrug": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រាយឈ្មោះថ្នាំអាឡែស៊ី"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គ្មានទិន្នន័យ"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រៀបរាប់ឈ្មោះថ្នាំដែលអ្នកអាឡែស៊ី"])}
    },
    "congenitalDisease": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះជំងឺប្រចាំកាយ"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គ្មានទិន្នន័យ"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រៀបរាប់ពីជំងឺប្រចាំកាយ"])}
    },
    "personalMedicine": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះថ្នាំផ្ទាល់ខ្លួន"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គ្មានទិន្នន័យ"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រៀបរាប់ឈ្មោះថ្នាំរបស់អ្នក"])}
    }
  },
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបង់ប្រាក់ក្នុង"])},
  "term": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គោលការណ៍​ភាព​ឯកជន"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជាក់ការយល់ព្រម"])}
  },
  "creditCard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទូទាត់តាម Credit / Debit"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ត"])},
    "placeholder": {
      "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ខែ/ឆ្នាំ"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះ​នៅ​លើ​កាត"])},
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXXX XXXX XXXX XXXX"])}
    },
    "label": {
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខកាត"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះ​នៅ​លើ​កាត"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផុតកំណត់"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])}
    },
    "errs": {
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខកាតត្រូវតែ16 ខ្ទង់"])},
      "expFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កាតផុតកំណត់នឹងត្រូវបំពេញដោយ MM/YY"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ខែដែលផុតកំណត់មិនត្រឹមត្រូវទេ"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឆ្នាំដែលផុតកំណត់មិនត្រឹមត្រូវទេ"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV ត្រូវតែបំពេញដោយ 3 ខ្ទង់"])},
      "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មាន​អ្វីមួយ​មិន​ត្រឹមត្រូវ!"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញឈ្មោះនៅលើកាត"])},
      "cardExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កាតរបស់អ្នកបានផុតកំណត់"])},
      "cardInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ព័ត៌មាននៅលើកាតមិនត្រឹមត្រូវទេ"])}
    }
  },
  "cancellation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បោះបង់ការកម្មង់ទិញ"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបញ្ជាក់ពីមូលហេតុដែលបោះបង់ការកម្មង់ទិញ"])}
  },
  "myCoupon": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គូប៉ុងបញ្ចុះតំលៃ"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកមិនទាន់មានគូប៉ុងបញ្ចុះតំលៃទេ"])},
    "useCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រើគូប៉ុង"])},
    "selectCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ជ្រើសរើសគូប៉ុង"])},
    "card": {
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចុះតំលៃ"])},
      "noMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គ្មានអប្បបរមា"])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អប្បបរមា"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្ងៃផុតកំណត់"])},
      "noExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គ្មានថ្ងៃផុតកំណត់"])}
    }
  },
  "myFavorite": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជីរាយនាមដែលចូលចិត្ត"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្នាំបានបន្ថែមដោយជោគជ័យ"])},
    "successRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្នាំបានដកចេញដោយជោគជ័យ"])}
  },
  "verifyUser": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផ្ទៀងផ្ទាត់លេខទូរស័ព្ទ"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញលេខទូរស័ព្ទ"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជាក់អត្តសញ្ញាណរបស់អ្នក"])},
    "getFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទទួលបានដោយឥតគិតថ្លៃ"])},
    "coins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " កាក់"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញ OTP"])}
  },
  "loginWithPhone": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូលដោយប្រើលេខទូរស័ព្ទ"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញលេខទូរស័ព្ទ (ដែលបានចុះឈ្មោះនៅ Makalin Clinic)"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជាក់អត្តសញ្ញាណរបស់អ្នក"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញ OTP"])}
  },
  "forgetPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ភ្លេចពាក្យសម្ងាត់របស់អ្នក?"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញលេខទូរស័ព្ទ (ដែលបានចុះឈ្មោះនៅ Makalin Clinic)"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជាក់អត្តសញ្ញាណរបស់អ្នក"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញ OTP"])}
  },
  "loginWithUsername": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូលដោយប្រើឈ្មោះអ្នកប្រើប្រាស់"])}
  },
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទំព័រដើម"])},
    "myDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្នាំរបស់ខ្ញុំ"])},
    "myCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កន្រ្តកទិញ​ឥវ៉ាន់"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រវត្តិរូប"])}
  },
  "profileMenu": {
    "myHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រវត្តិនៃការទិញរបស់ខ្ញុំ"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ណែនាំមិត្តភក្តិសន្សំពិន្ទុ"])},
    "myDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្នាំដែលខ្ញុំកំពុងប្រើ"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អាស័យដ្ឋាន"])},
    "bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ព័ត៌មានគណនីធនាគារ"])},
    "helpService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សេវាកម្មជំនួយ"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អំពី​ខ្ញុំ"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចំណូលចិត្ត"])}
  },
  "myDiaryDrugs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្នាំកំពុងប្រើ"])},
    "drugGraphTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចំនួនថ្នាំដែលប្រើញឹកញាប់"])},
    "continueDrugsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 ថ្នាំដែលកំពុងប្រើជាបន្តបន្ទាប់"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកមិនទាន់មានបញ្ជីថ្នាំ"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទាំងអស់"])},
    "recommendTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទំនិញដែលបានណែនាំ"])},
    "frequencyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទំនិញដែលទិញញឹកញាប់"])},
    "latestPurchaseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទំនិញដែលបានទិញថ្មីៗនេះ"])},
    "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ដាក់ទំនិញទាំងអស់ចូលក្នុងកន្ត្រក"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ជ្រើសរើសទាំងអស់"])},
    "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ដកចេញទាំងអស់"])},
    "addSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ថែមទំនិញដែលបានជ្រើសរើសដាក់ចូលទៅក្នុងកន្ត្រក"])}
  },
  "myCart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Cart"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកមិនទាន់មានទំនិញនៅក្នុងកន្ត្រក"])},
    "shopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទិញឥឡូវនេះ"])},
    "suggestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទិញជាមួយគ្នាចំនេញកាន់តែច្រើន"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ថែមទំនិញចូលកន្ត្រករួចរាល់"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃទំនិញសរុប"])},
    "excludeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មិនរាប់បញ្ចូលថ្លៃដឹកជញ្ជូនទេ"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជាទិញទំនិញ"])}
  },
  "checkout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សរុបការបញ្ជាទិញ"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["វិធីដឹកជញ្ជូនទំនិញ"])},
    "sendByPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ការដឹកជញ្ជូនតាមប្រៃសណីយ៍"])},
    "takeaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មកទទួលទំនិញដោយខ្លួនឯងនៅតាមសាខា"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រាយឈ្មោះទំនិញ"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃសរុប"])},
    "sumTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃទំនិញ (រួម VAT 7%)"])},
    "shippingFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្លៃសេវា​ដឹកជញ្ជូន"])},
    "sumDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គូប៉ុងបញ្ចុះតំលៃ"])},
    "sumVat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["VAT ", _interpolate(_named("percent")), "%"])},
    "sumGrandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សរុបទាំងអស់"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["វិធីទូទាត់ថ្លៃទំនិញ"])},
    "emailDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញអ៊ីមែលរបស់អ្នក"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ៊ីមែល"])},
    "referalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខ​កូដ​ការ​អញ្ជើញ"])},
    "placeHolderReferalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បំពេញលេខកូដអញ្ជើញ"])},
    "acceptParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ខ្ញុំយល់ព្រមចំពោះការសងប្រាក់វិញ និងគោលការណ៍ផលិតផល(ចុច ដើម្បីអានពត៌មានលំអិត )លេខទំនាក់ទំនង: 064-181-6580-2"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទូទាត់ប្រាក់"])},
    "changeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផ្លាស់ប្តូរអាស័យដ្ឋានដឹកជញ្ជូន"])},
    "goToAddAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ថែមអាស័យដ្ឋាន"])},
    "pleaseSelectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបន្ថែមអាស័យដ្ឋានដឹកជញ្ជូន"])},
    "pleaseSelectPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមជ្រើសរើសវិធីទូទាត់ប្រាក់"])}
  },
  "myAddresses": {
    "changeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផ្លាស់ប្តូរអាស័យដ្ឋានដឹកជញ្ជូន"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អាស័យដ្ឋានដឹកជញ្ជូន"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកមិនទាន់មានអាស័យដ្ឋាន"])},
    "mainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អាស័យដ្ឋានបច្ចុប្បន្ន"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បំពេញអាស័យដ្ឋានថ្មីរួចរាល់"])},
    "successUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផ្លាស់ប្តូរអាស័យដ្ឋានរួចរាល់"])},
    "successDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លុបអាស័យដ្ឋានរួចរាល់"])}
  },
  "addAddresses": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ថែមអាស័យដ្ឋាន"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កែសម្រួលអាស័យដ្ឋាន"])},
    "inCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ក្នុងប្រទេស"])},
    "abroad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ក្រៅប្រទេស"])},
    "form": {
      "receiveName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះអ្នកទទួល"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខទូរស័ព្ទ"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អាស័យដ្ឋាន"])},
      "addressNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អាស័យដ្ឋានលំអិត (លេខផ្ទះ ផ្លូវ ភូមិឃុំ ฯ)"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ខេត្ត"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សង្កាត់"])},
      "subDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ភូមិ/ឃុំ"])},
      "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខកូដប្រៃសណីយន៍"])},
      "setMainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អាស័យដ្ឋានបច្ចុប្បន្ន"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រក្សាទុក"])},
      "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រំលង"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផ្ទះ"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កន្លែងធ្វើការ"])}
    }
  },
  "allProducts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទំនិញទាំងអស់"])}
  },
  "referral": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ណែនាំមិត្តភក្តិសន្សំពិន្ទុ"])},
    "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខ​កូដ​ការ​អញ្ជើញ"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចំលង "])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជាក់"])},
    "myscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ពិន្ទុរបស់យើង"])},
    "friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចំនួនមិត្តភក្តិ"])},
    "inputRefCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បំពេញលេខ​កូដ​ការ​អញ្ជើញ"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រវត្តិការសន្សំពិន្ទុ"])},
    "useCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ណែនាំមិត្តភក្តិសន្សំពិន្ទុ"])},
    "useQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ស្កេនដើម្បីប្រមូលពិន្ទុ"])},
    "useCodeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកទទួលបានពិន្ទុពីមិត្តភក្តិដែលបញ្ចូលលេខកូដ​ការ​អញ្ជើញរបស់អ្នក"])},
    "useQRCodeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកបានទទួលពិន្ទុពីការស្កេនកូដ QR"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ពិន្ទុ"])}
  },
  "order": {
    "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មាន​អ្វីមួយ​មិន​ត្រឹមត្រូវ!"])},
    "successPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទូទាត់ប្រាក់រួចរាល់"])},
    "errorPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទូទាត់ប្រាក់មិនបានសំរេច សូមទូទាត់ជាថ្មីម្តងទៀត"])},
    "creditCardErrorPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ការទូទាត់ប្រាក់មិនបានសំរេចសូមធ្វើប្រតិបត្តិការម្តងទៀត"])},
    "orderAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជាទិញម្តងទៀត"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទំនិញដែលបញ្ជាទិញ"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order ID"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["វិធីដឹកជញ្ជូនទំនិញ"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទូទាត់ប្រាក់តាម"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សរុប"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខបញ្ជាទិញ"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទូទាត់ប្រាក់តាម"])},
    "sumTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃទំនិញសរុប"])},
    "shippingFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្លៃសេវាដឹកជញ្ជូន"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គូប៉ុងបញ្ចុះតំលៃ"])},
    "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សរុបការបញ្ជាទិញ"])},
    "gainPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកនឹងបានពិន្ទុសន្សំ"])},
    "sendByPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ដឹកជញ្ជូនតាមប្រៃសណីយ៍"])},
    "parcelTrackNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខតាមដានកញ្ចប់ទំនិញ"])},
    "itemTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះទំនិញ"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចំនួន"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រភេទ"])},
    "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លុបចោលការបញ្ជាទិញ"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ត្រឡប់ទៅទំព័រដើម"])},
    "noParcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មិនទាន់មានលេខតាមដានកញ្ចប់ទំនិញ"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទូទាត់ប្រាក់"])},
    "qrDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបំពេញអោយរួចរាល់ បន្ទាប់មកសូមចុចបិទខាងក្រោម"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បិទ"])},
    "successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ការបញ្ជាទិញត្រូវបានលុបចោលដោយជោគជ័យ"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មិនអាចលុបចោលការបញ្ជាទិញបាន"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមទាក់ទង admin ដើម្បីទទួលប្រាក់ត្រឡប់មកវិញ"])},
    "statuses": {
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទូទាត់ប្រាក់រួចរាល់"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បានបញ្ជូនទំនិញរួចហើយ"])},
      "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បានទទួលទំនិញរួចរាល់"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បានលុបចោល"])}
    }
  },
  "latestReceive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្នាំដែលពេទ្យចេញចុងក្រោយ"])},
    "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ថែមទំនិញទាំងអស់ទៅក្នុងកន្ត្រក"])}
  },
  "filter": {
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ស្វែងរកតាមប្រភេទ"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃច្បាស់លាស់"])},
    "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃលក់"])},
    "priceRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃលក់(฿)"])},
    "inputLowestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃទាបបំផុត"])},
    "inputHighestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃខ្ពស់បំផុត"])},
    "priceFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ពី"])},
    "priceTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ដល់"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តម្រង"])},
    "sortLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តម្រៀបតាម"])},
    "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ស្វែងរកលំអិត"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះ"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃ"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចុះតំលៃ"])},
    "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចំនួនលក់បាន"])},
    "numVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចំនួនអ្នកចូលមើល"])},
    "tab": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះ"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចុះតំលៃ"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តំលៃ"])},
      "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទំនិញលក់ដាច់"])},
      "numVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចំនួនអ្នកចូលមើល"])}
    }
  },
  "product": {
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សាកសួរ"])},
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ថែមក្នុងកន្រ្តក"])},
    "buyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទិញ​ឥឡូវនេះ"])}
  },
  "laserService": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សេវាកម្មឡាស៊ែរ"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ធ្វើការកក់ទុក"])},
    "reserverTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ពត៌មានអ្នកកក់"])},
    "packageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កញ្ចប់"])},
    "reserverName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះ-នាមត្រកូល"])},
    "reserverPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លេខទូរស័ព្ទ"])},
    "getOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទទួល OTP"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កក់ការណាត់ជួបគ្រូពេទ្យ"])},
    "fillOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចូលលេខកូដ OTP"])},
    "sendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រព័ន្ធបានផ្ញើរ SMS លេខកូដ OTP ទៅលេខទូរស័ព្ទ"])},
    "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បានកក់ទុកដោយជោគជ័យ"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកបានកក់ទុកការណាត់ជួបគ្រូពេទ្យដោយជោគជ័យ"])},
    "memberTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបញ្ជាក់លេខទូរស័ព្ទរបស់អ្នក"])},
    "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូលទៅកាន់ទំព័រLogin "])},
    "connectMakalin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូលទៅកាន់ទំព័របញ្ជាក់លេខទូរស័ព្ទ"])},
    "successConnectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បានភ្ជាប់ប្រវត្តិដោយជោគជ័យ"])},
    "successConnectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមអបអរសាទរអ្នកបានពិន្ទុ 100 coins"])},
    "backToReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ត្រឡប់ទៅទំព័រណាត់ជួប"])}
  }
}