<template>
  <div class="order-by-id-container">
    <div class="top-header-wrapper">
      <button @click="back">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('order.title') }}</p>
    </div>

    <span v-if="!getUser">
    <div class="green-bar">
      ขอบคุณที่สั่งซื้อสินค้ากับเรา
    </div>
      &nbsp; &nbsp;

      <div class="capture-screen-wrapper">
          <div>กรุณาบันทึกภาพหน้าจอ</div>
          <div>เพื่อจำเลขคำสั่งซื้อของคุณ</div>
          <div class="font">Please capture a screenshot to remember your order number.</div>

      </div>
    </span>
    <div class="content-wrapper">
      <div class="order-number-wrapper">
        
        <p class="title">{{ t('order.orderNumber') }}</p>
        <p
          class="number"
          :class="{
            'is-cancelled': statusLevel === 5
          }"
        >
          {{ order?.order_number }}
        </p>
      </div>
      <div class="row">
        <p class="title">{{ t('order.payment') }}</p>
        <p class="detail">{{ order?.payment_channel_text }}</p>
      </div>
      <div class="row">
        <p class="title">{{ t('order.sumTotal') }}</p>
        <p class="detail">{{ numberWithCommas(order?.total) }} ฿</p>
      </div>
      <div class="row">
        <p class="title">{{ t('order.shippingFee') }}</p>
        <p class="detail">{{ numberWithCommas(order?.shipping_fee) }} ฿</p>
      </div>
      <div class="row">
        <p class="title">{{ t('order.discount') }}</p>
        <p class="detail">{{ order?.coupon_discount ? `-${numberWithCommas(order?.coupon_discount)}` : numberWithCommas(order?.coupon_discount) }} ฿</p>
      </div>
      <div class="row">
        <p class="title"><b>{{ t('order.grandTotal') }}</b></p>
        <p class="detail"><b>{{ numberWithCommas(order?.grand_total) }} ฿</b></p>
      </div>
      <div class="row">
        <p class="title">{{ t('order.gainPoint') }}</p>
        <img src="@/assets/icons/m-coin.svg">
        <p class="detail">+{{ numberWithCommas(order?.coin_amount) }}</p>
      </div>
      <div class="statuses-wrapper" v-if="statusLevel === 5">
        <div class="status-item-wrapper cancelled" v-if="order?.paid_datetime">
          <div class="status-badge">
            <img :src="require(`@/assets/icons/order-statuses/paid-cancelled.svg`)" alt="paid">
          </div>
          <p class="status">
            {{ t('order.statuses.paid') }}
          </p>
          <p class="time">{{ order?.paid_datetime }}</p>
        </div>
        <div class="status-item-wrapper cancelled">
          <div class="status-badge">
            <img :src="require(`@/assets/icons/order-statuses/cancelled.svg`)" alt="paid">
          </div>
          <p class="status">
            {{ t('order.statuses.cancelled') }}
          </p>
        </div>
      </div>
      <div class="statuses-wrapper" v-else>
        <div class="status-item-wrapper" :class="{ 'active': statusLevel === 2 }">
          <div class="status-badge">
            <img :src="require(`@/assets/icons/order-statuses/paid${statusLevel === 2 ? '-active' : ''}.svg`)" alt="paid">
          </div>
          <p class="status">
            {{ t('order.statuses.paid') }}
          </p>
          <p class="time">{{ order?.paid_datetime }}</p>
        </div>
        <div class="status-item-wrapper">
          <div class="status-badge">
            <img :src="require(`@/assets/icons/order-statuses/shipping${statusLevel === 3 ? '-active' : ''}.svg`)" alt=""/>
          </div>
          <p class="status">
            {{ t('order.statuses.shipping') }}
          </p>
          <p class="date">{{ order?.shipped_date }}</p>
        </div>
        <div class="status-item-wrapper">
          <div class="status-badge">
            <img :src="require(`@/assets/icons/order-statuses/delivered${statusLevel === 4 ? '-active' : ''}.svg`)" alt=""/>
          </div>
          <p class="status">
            {{ t('order.statuses.delivered') }}
          </p>
        </div>
      </div>
      <p class="return-wrapper" v-if="order?.paid_datetime && statusLevel === 5">
        {{ t('order.return') }}
      </p>
      <div class="address-wrapper">
        <p class="title">{{ t('order.sendByPost') }}</p>
        <p class="address-detail">
          {{ order?.first_name }} {{ order?.last_name }}<br/>
          {{ order?.address }} {{ order?.zipcode }}<br/>
          {{ order?.phone }}
        </p>
      </div>
      <div class="parcel-wrapper">
        <p class="title">{{ t('order.parcelTrackNo') }}:</p>
        <p class="detail" v-if="order?.ems_tracking">{{ order?.ems_tracking }}</p>
        <p class="detail no-parcel" v-else>{{ t('order.noParcel') }}</p>
      </div>
      <p class="item-title">{{ t('order.itemTitle') }}</p>
      
      <ProductInOrderCard
        v-for="product in order.order_items"
        :product="product"
        :key="product.product_id"
      />

      <MButton class="mt-2" v-if="statusLevel === 1 && order.payment_channel !== 'credit_card'" @click="pay">
        {{ t('order.pay') }}
      </MButton>
      <MButton class="mt-2" v-else-if="statusLevel === 1 && order.payment_channel === 'credit_card'" @click="orderAgain">
        {{ t('order.orderAgain') }}
      </MButton>
      <MButton outline red class="mt-2 cancel-button" style="background-color: red; color: white;" @click="goToCancel" v-if="statusLevel < 3">
        {{ t('order.cancelOrder') }}
      </MButton>
      <MButton outline red class="mt-2 cancel-button" style="background-color: green; color: white;" @click="goToHome" v-if="statusLevel != 1">
        {{ t('order.backHome') }}
      </MButton>
      
    </div>
    <div class="qr-code-preview-wrapper" :class="{ 'show': qrCode }">
      <img :src="qrCode" alt="qr-code">
      <MButton outline class="mb-2" @click="downloadQrCode(qrCode)">{{ t('downloadQrCode') }}</MButton>
      <Timer v-if="remainingSeconds" :seconds="remainingSeconds" />
      <p class="qr-description">
        {{  t('order.qrDescription') }}
      </p>
      <MButton class="mt-2" @click="close">{{ t('order.close') }}</MButton>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref, watchEffect, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import getStatusLevel from '@/utils/getStatusLevel'
import numberWithCommas from '@/utils/numberWithCommas'

import ProductInOrderCard from '@/components/ProductInOrderCard'
import MButton from '@/components/Form/MButton'
import Timer from '@/components/Timer'
import referralApi from '@/api/referral';
import patrickApi from '@/api/patrick'

export default {
  name: 'MyOrderById',
  components: {
    ProductInOrderCard,
    MButton,
    Timer,
  },
  setup() {
    /** context */
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const id = route.params.id
    const { status } = route.query
    const isRedirect = route.query.redirect

    /** reactive */
    const pingStatusInterval = ref(null)

    /** computed */
    const order = computed(() => store.state.order.currentOrder)
    const qrCode = computed(() => store.state.order.qrCode)
    const remainingSeconds = computed(() => store.state.order?.payment?.remain_seconds)
    const statusLevel = computed(() => getStatusLevel(order.value?.status))
    const getUser = computed(() => store.getters['user/getUser'])

    const user = computed(() => store.getters['user/getUserProfile'])

    const submitReferral = async (refCode) => {
    const rewardData = {
      userId: refCode,
      rewardId: 1, 
      dateClaim: new Date().toISOString().split('T')[0],
      type: 'MKL',
      referrerId: user.value.id,
      status: 'WAITING',
      order_id: id
    };

    if(refCode == user.value.id){
      return;
    }
    const visitData = await patrickApi.getVisit(user.value.makalin_user_id);
    if(visitData.Status == false){
      return;
    }

  try {
    const rewards = await referralApi.getUserRewards(user.value.id);
    if(rewards.length == 0){
      await referralApi.postUserReward(rewardData);
    }
  } catch (error) {
    console.error('Error posting referral:', error);
  }
};
    
    /** mounted */
    onMounted(() => {
      store.dispatch('order/fetchGetOrderById', id)
      if (isRedirect) {
        store.dispatch('order/fetGetPaymentDetailById', id)
      }

      let refCode = localStorage.getItem('refCode')
      if(refCode != undefined){
        submitReferral(refCode);
      }

      if (status === 'scb_success' || status === 'credit_card_success') {
        store.commit('snackBar/SET_DATA', { message: t('order.successPayment'), color: 'success' })
      }
      if (status === 'scb_failed') {
        store.commit('snackBar/SET_DATA', { message: t('order.errorPayment'), color: 'error' })
      }
      if (status === 'credit_card_failed') {
        store.commit('snackBar/SET_DATA', { message: t('order.creditCardErrorPayment'), color: 'error' })
      }

    })

    /** method */
    const back = () => router.replace('/my-orders')
    const pay = () => {
      store.dispatch('order/fetGetPaymentDetailById', id)
    }
    const orderAgain = async () => {
      try {
        await order.value.order_items.forEach(async orderItem => {
          await store.dispatch('cart/fetchAddProduct', {
            product_id: orderItem.product_id,
            quantity: orderItem.quantity,
            add_from: 'user',
          })
        })
        router.replace('/my-cart')
      }
      catch (err) {
        store.commit('snackBar/SET_DATA', { message: t('order.creditCardErrorPayment'), color: 'error' })
      }
    }
    const close = () => {
      store.commit('order/RESET_QR_CODE')
      store.commit('order/RESET_PAYMENT_DETAIL')
    }
    const goToCancel = () => {
      router.push(`/my-orders/${id}/cancellation`)
    }

    const goToHome = () => {
      router.push(`/`)
    }

    const downloadQrCode = (link) => {
      var a = document.createElement('a')
      a.href = link
      a.download = `prompay-qr-code-${id}.png`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
    
    /** watch */
    watchEffect(() => {
      console.log(statusLevel.value)
      console.log(pingStatusInterval.value)
      if (!pingStatusInterval.value) {
        pingStatusInterval.value = setInterval(() => {
          store.dispatch('order/fetchGetOrderStatus', id)
        }, 5000)
        
      }
      if (statusLevel.value > 1) {
        if (pingStatusInterval.value) {
          store.commit('order/RESET_QR_CODE')
          store.commit('order/RESET_PAYMENT_DETAIL')
          store.dispatch('order/fetchGetOrderStatus', id)
          store.dispatch('user/fetchGetUserProfile')
          clearInterval(pingStatusInterval.value)
        } 
      }
      
      

    })

    /** unmounted */
    onUnmounted(() => {
      if (pingStatusInterval.value) {
        clearInterval(pingStatusInterval.value)
      }
    })

    return {
      t,
      pay,
      back,
      close,
      order,
      qrCode,
      goToCancel,
      goToHome,
      orderAgain,
      statusLevel,
      remainingSeconds,
      downloadQrCode,
      numberWithCommas,
      getUser
    }
  }
}

</script>

<style lang="scss" scoped>

.order-by-id-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 56px;
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
}
.green-bar {
 text-align: center;
 padding: 8px;
 background-color: #DCEFDD;
 font-weight: bold;
 
}

.capture-screen-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  text-align: center;
  margin: 0 auto;
  padding: 15px; 
  border-radius: 8px;
  border: 1px solid #afafaf;
  width: 80%; 
  line-height: 1.6;
  font-weight: bold;
  > .font {
    font-size: 12px;
  }
}

.order-number-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: var(--secondary-success-color);
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #afafaf;
  > .title {
    font-size: 16px;
  }
  > .number {
    font-size: 20px;
    font-weight: bold;
    color: var(--success-color);
  }
}

.row {
  display: flex;
  margin: 12px 0 0;
  justify-content: space-between;
  align-items: center;
  > img {
    margin-left: auto;
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
  .title {
    font-size: 16px;
  }
  .detail {
    font-size: 18px;
  }
}

.address-wrapper {
  padding: 12px 8px;
  border: 1px solid #afafaf;
  margin: 12px 0 0;
  border-radius: 8px;
  > .title {
    font-weight: bold;
    font-size: 18px;
  }
  > .address-detail {
    font-size: 16px;
  }
}

.parcel-wrapper {
  padding: 12px 8px;
  border: 1px solid #afafaf;
  margin: 12px 0 0;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  > .title {
    font-size: 18px;
  }
  > .detail {
    font-weight: bold;
    font-size: 20px;
    color: var(--success-color);

    &.no-parcel {
      color: var(--grey-color);
    }
  }
}

.item-title {
  margin: 12px 0;
  font-size: 18px;
  font-weight: bold;
}

.statuses-wrapper {
  display: flex;
  padding: 8px 20px;
  .status-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
    .time {
      text-align: center;
    }
    &:not(:last-of-type) {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 16px;
        height: 2px;
        transform: translate(50%, 50%);
        background-color: var(--grey-color);
        z-index: -1;
      }
    }
    
    .status-badge {
      width: 34px;
      height: 34px;
      border: 2px solid var(--grey-color);
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .status {
      color: var(--grey-color);
      font-size: 16px;
    }
    .date {
      font-size: 14px;
    }
    &.active {
      .status-badge {
        border: 2px solid var(--success-color);
      }
      .status {
        color: var(--success-color);
      }
      &:not(:last-of-type) {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 16px;
          height: 2px;
          transform: translate(50%, 50%);
          background-color: var(--success-color);
          z-index: -1;
        }
      }
    }
    &.cancelled {
      .status-badge {
        border: 2px solid var(--error-color);
      }
      .status {
        color: var(--error-color);
      }
      &:not(:last-of-type) {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 16px;
          height: 2px;
          transform: translate(50%, 50%);
          background-color: var(--error-color);
          z-index: -1;
        }
      }
    }
  }
}

.qr-code-preview-wrapper {
  position: fixed;
  z-index: 9;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 20px;
  right: 20px;
  transform: translateY(100vh);
  transition: all 0.5s ease-in-out;
  &.show {
    transform: translateY(-50%);
  }
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translate(-50%, 100vh);
    width: 700px;
    &.show {
      transform: translate(-50%, -50%);
    }
  }
  > img {
    max-height: 400px;
    object-fit: contain;
  }
  .qr-description {
    white-space: pre-line;
    text-align: center;
    font-size: 22px;
  }
}

.return-wrapper {
  color: var(--error-color);
  text-align: center;
  font-size: 20px;
}

.cancel-button {
  background-color: transparent; /* Keeps the background transparent for the 'outline' style */
  color: var(--error-color); /* Changes text color to red */
  border: 1px solid var(--error-color); /* Changes border color to red */

  &:hover {
    background-color: var(--error-color); 
    color: white;
    border-color: var(--error-color); 
  }
}

</style>
