<template>
  <div class="about-us-container">
    <div class="top-header-wrapper">
      <button @click="back">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('aboutUs.title') }}</p>
    </div>
    <div class="content-wrapper">
      <div class="about-us-content-wrapper">
        <div v-html="aboutUs"></div>
      </div>
    </div>
    <button class="floating-button" @click="goToUrl">
      <img src="@/assets/icons/line.svg" alt="Navigate">
    </button>
  </div>
</template>


<script>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AboutUs',
  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const store = useStore()

    const aboutUs = computed(() => store.state.frontpage?.aboutUs)

    const back = () => router.back()
    const goToUrl = () => {
      window.location.href = 'https://liff.line.me/1655848771-Pd2DyNyl?pv=1'
    }

    onMounted(() => {
      store.dispatch('frontpage/fetchAboutUs')
    })

    return {
      t,
      back,
      aboutUs,
      goToUrl,
    }
  }
}
</script>

<style lang="scss" scoped>
.about-us-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 56px;
  position: relative;
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.about-us-content-wrapper {
  padding: 12px;
  > p {
    font-size: 24px;
    text-align: center;
    margin-bottom: 12px;
  }
  > span, > span > * {
    padding: 8px 0;
    text-align: center;
    font-size: 20px;
  }
}
ul {
  list-style: decimal;
  padding: 20px;
  > li {
    font-size: 20px;
    margin-bottom: 12px;
  }
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.floating-button img {
  width: 24px;
  height: 24px;
}
</style>
